import React from 'react'
import { Translation, Trans } from 'react-i18next'

export const KEYWORD_ENGINE_TOOLTIPS = {
  historicalSearchTrends: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.historicalSearchTrends',
            'Keyword Search Volume trend over the last 30 days.'
          )}
        </div>
      )}
    </Translation>
  ),
  estimatedExactSearchVolume: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.estimatedExactSearchVolume',
            'The number of Amazon searches over the past month that exactly match the keyword, excluding plurals and misspellings.'
          )}
        </div>
      )}
    </Translation>
  ),
  updatedAt: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.updatedAt',
            'The date of the last volume search update.'
          )}
        </div>
      )}
    </Translation>
  ),
  monthlyTrend: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.monthlyTrend',
            'Keyword search volume over the last 30 days vs. the prior 30 days.'
          )}
        </div>
      )}
    </Translation>
  ),
  quarterlyTrend: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.quarterlyTrend',
            'Keyword search volume over the last 90 days vs. the prior 90 days.'
          )}
        </div>
      )}
    </Translation>
  ),
  estimatedBroadSearchVolume: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.estimatedBroadSearchVolume',
            'The number of Amazon searches over the past month that broadly match the keyword, including the phrase, plurals, misspellings or synonyms.'
          )}
        </div>
      )}
    </Translation>
  ),
  category: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.category',
            'The most popular parent category across the top 10 ranked listings.'
          )}
        </div>
      )}
    </Translation>
  ),
  avgGiveaway: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.avgGiveaway',
            'The estimated number of daily promotions required to reach a top 3 ranking in 7-14 days.'
          )}
        </div>
      )}
    </Translation>
  ),
  exactSuggestedBidMedian: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.exactSuggestedBidMedian',
            'The estimated median cost of an exact match PPC ad.'
          )}
        </div>
      )}
    </Translation>
  ),
  broadSuggestedBidMedian: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.broadSuggestedBidMedian',
            'The estimated median cost of a broad match PPC ad.'
          )}
        </div>
      )}
    </Translation>
  ),
  easeOfRankingScore: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.easeOfRankingScore',
            'How easy it is for a new product to rank for this keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  relevancyScore: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.relevancyScore',
            'A high score indicating how closely related the keyword is to the seed keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  organicProductCount: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.organicProductCount',
            'Total number of Products returned on Amazon for this keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  sponsoredProductCount: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredProductCount',
            'Total number of sponsored products recently detected for the keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  organicRank: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.organicRank',
            'Organic position of the highlighted ASIN. This rank ignores sponsored ASINs.'
          )}
        </div>
      )}
    </Translation>
  ),
  sponsoredRank: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredRank',
            'Sponsored position of the highlighted ASIN. This count ignores organic ASINs.'
          )}
        </div>
      )}
    </Translation>
  ),
  overallRank: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.overallRank',
            "Overall Rank is the highlighted ASIN's overall positioning in the search results. This rank includes both organic and sponsored products."
          )}
        </div>
      )}
    </Translation>
  ),
  organicRankingAsins: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.organicRankingAsins',
            'Number of searched competitor ASINs, excluding the highlighted ASIN, that rank in the top 100 organic products for the keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  sponsoredRankingAsins: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredRankingAsins',
            'Number of searched competitor ASINs, excluding the highlighted ASIN, that rank in the top 100 sponsored products for the keyword.'
          )}
        </div>
      )}
    </Translation>
  ),
  avgCompetitorOrganicRank: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.avgCompetitorOrganicRank',
            'Average organic rank for the ranking competitor ASINs, excluding the highlighted ASIN. How well your searched ASINs are ranking for this keyword on average.'
          )}
        </div>
      )}
    </Translation>
  ),
  avgCompetitorSponsoredRank: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.avgCompetitorSponsoredRank',
            'Average sponsored rank for the indexed comparison ASINs, excluding the highlighted ASIN. This is how well the ads for your searched ASINs are ranking for this keyword on average.'
          )}
        </div>
      )}
    </Translation>
  ),
  relativeOrganicPosition: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.relativeOrganicPosition',
            'The organic rank of the highlighted ASIN relative to the other ASINs. For example, if the highlighted ASIN ranks better than all the competitors then the rank will be 1.'
          )}
        </div>
      )}
    </Translation>
  ),
  relativeSponsoredPosition: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.relativeSponsoredPosition',
            'The sponsored rank of the highlighted ASIN relative to the other ASINs. For example, if the highlighted ASIN ranks better than all the competitors then the rank will be 1.'
          )}
        </div>
      )}
    </Translation>
  ),
  wordCount: (
    <Translation ns="constants">
      {t => (
        <div>
          {t(
            'constants:KEYWORD_ENGINE_TOOLTIPS.wordCount',
            'Number of individual words in the keyword phrase.'
          )}
        </div>
      )}
    </Translation>
  ),
  noData: {
    category: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.category">
        No category available.
      </Trans>
    ),
    avgGiveaway: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.avgGiveaway">
        No giveaway data available.
      </Trans>
    ),
    noAmazonSearchVolume: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.noAmazonSearchVolume">
        No search volume available.
      </Trans>
    ),
    noJungleSearchVolume: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.noJungleSearchVolume">
        No search volume available.
      </Trans>
    ),
    exactSuggestedBidMedian: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.exactSuggestedBidMedian">
        No estimated PPC data available.
      </Trans>
    ),
    broadSuggestedBidMedian: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.broadSuggestedBidMedian">
        No estimated PPC data available.
      </Trans>
    ),
    easeOfRankingScore: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.easeOfRankingScore">
        No ranking score available.
      </Trans>
    ),
    keywordsTracked: (
      <Trans i18nKey="constants:KEYWORD_ENGINE_TOOLTIPS.noData.keywordsTracked">
        No keywords tracked.
      </Trans>
    )
  }
}
