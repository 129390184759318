// Feature Names for the feature flags
export const Features = {
  AI_CHATBOT: 'fflag.ai.chatbot',
  COBALT_HIDE_EDIT_SEGMENTS: 'fflag.cobalt.hideEditSegments',
  COBALT_MAP_ALERTS: 'fflag.cobalt.mapAlerts',
  COBALT_MONTHLY_ASIN_LIMITS: 'fflag.cobalt.monthlyAsinLimits',
  COBALT_STATIC_SEGMENT: 'fflag.cobalt.staticSegment',
  COBALT_ASIN_EXPLORER_MAY_2020_TOOLTIP:
    'fflag.cobalt.asinExplorer.may2020Tooltip',
  COBALT_SHARE_AND_TRENDS_3P_SELLER: 'fflag.cobalt.shareTrends.3pSeller',
  COBALT_SHARE_AND_TRENDS_3P_SELLER_EXCEL_EXPORT:
    'fflag.cobalt.shareTrends.3pSeller.excelExport',
  COBALT_SHARE_AND_TRENDS_TOKENIZATION: 'fflag.cobalt.shareTrends.tokenization',
  COBALT_TOLKIEN_PHASE_1: 'fflag.cobalt.tolkien.phase1',
  COMPETITIVE_INTELLIGENCE_ADDONS: 'fflag.competitiveIntelligence.addOns',
  COMPETITIVE_INTELLIGENCE_CHART_BRUSH:
    'fflag.competitiveIntelligence.chartBrush',
  COMPETITIVE_INTELLIGENCE_LISTING_ANALYSIS:
    'fflag.competitiveIntelligence.listingAnalysis',
  COMPETITIVE_INTELLIGENCE_MVP: 'fflag.competitiveIntelligence.mvp',
  COMPETITIVE_INTELLIGENCE_POSITION_TRACKER:
    'fflag.competitiveIntelligence.positionTracker',
  COMPETITIVE_INTELLIGENCE_PRODUCT_SLIDEOUT:
    'fflag.competitiveIntelligence.slideout',
  COMPETITIVE_INTELLIGENCE_AD_OPPORTUNITIES:
    'fflag.competitiveIntelligence.adOpportunities',
  KEYWORDS_SHARE_OF_VOICE: 'fflag.keywords.shareOfVoice',
  MONEY_BACK_GUARANTEE: 'fflag.account.moneyBackGuarantee',
  NEW_SUBSCRIPTIONS_PAGE: 'fflag.account.newSubscriptionsPage',
  OPPORTUNITY_FINDER_TABLE_SPARKLINE: 'fflag.opportunityFinder.sparkline',
  PRODUCT_INSIGHTS_PAGINATION: 'fflag.cobalt.productInsights.pagination',
  PROFIT_OVERVIEW_AI_SUMMARY: 'fflag.salesAnalytics.profitOverview.aiSummary',
  SEGMENT_BUILDER_SHARE_WITH_TEAM: 'fflag.segmentManager.shareWithTeam',
  SEGMENT_BUILDER_V2_MODALS: 'fflag.cobalt.segmentBuilderV2Modals',
  SELLER_FEATURES_DISABLED: 'fflag.sellerFeatures.disabled',
  SELLER_FEATURES_MAINTENANCE: 'fflag.sellerFeatures.maintenance',
  HIDE_EXCEL_EXPORT_BUTTON: 'fflag.cobalt.shareTrends.hideExcelExportButton',
  COBALT_WEEKLY_PROCESSING: 'fflag.cobalt.processing.weeklyprocessing.enabled',
  COBALT_IGNORE_NEW_ASINS: 'fflag.cobalt.processing.ignoreNewAsins.enabled',
  COBALT_SEGMENT_MANAGER_NEW_TOOLTIPS:
    'fflag.cobalt.segmentManager.newTooltips',
  COBALT_SEGMENT_MANAGER_CATEGORY_PARAM_MODAL:
    'fflag.cobalt.segmentManager.categoryParamModal',
  COBALT_SHARE_AND_TRENDS_ASIN_LIMITS_PHASE_2:
    'fflag.cobalt.shareTrends.ASINLimits.phase2',
  COBALT_SHARE_AND_TRENDS_UNIT_SALES_ROUNDING_DISABLED:
    'fflag.cobalt.shareTrends.segmentProductDay.disableRounding',
  COBALT_SHARE_AND_TRENDS_IN_APP_EXPORT: 'fflag.cobalt.shareTrends.inAppExport',
  COBALT_SHARE_AND_TRENDS_AUTO_ENFORCE_ASIN_LIMITS:
    'fflag.cobalt.shareTrends.autoEnforceASINLimits',
  CN_THEME: 'fflag.cn.theme',
  LISTING_BUILDER_DISABLE_SYNC: 'fflag.listingBuilder.disableSync',
  REVIEW_AUTOMATION_REVIEWS_CHART: 'fflag.reviewAutomation.reviewsChart',
  // This is being used not as a feature flag, but as a way to gate FBA reimbursements, so that users without a plan, or with Basic don't actually see it.
  // For us to remove it, would likely require some feature limiting to replace the behavior.
  INVENTORY_REIMBURSEMENTS: 'fflag.inventoryReimbursements.landingPage'
}
