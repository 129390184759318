import { INVENTORY_MANAGER_DATA_ATTRIBUTE_IDS as DataIds } from './data_attributes/inventory_manager'

export const INVENTORY_FORECAST_COLUMNS = [
  {
    displayName: 'Preview',
    value: 'amazon_image_url',
    name: 'amazon_image_url',
    selected: true
  },
  {
    displayName: 'ASIN',
    value: 'asin',
    name: 'asin',
    selected: false
  },
  {
    displayName: 'SKU',
    value: 'sku',
    name: 'sku',
    selected: true
  },
  {
    displayName: 'Product Title',
    value: 'title',
    name: 'title',
    selected: true
  },
  {
    displayName: 'Inventory Status',
    value: 'inventory_status',
    name: 'inventory_status',
    selected: true
  },
  {
    displayName: 'Date to Reorder',
    value: 'date_to_reorder',
    name: 'date_to_reorder',
    selected: true
  },
  {
    displayName: 'Reorder Quantity',
    value: 'reorder_qty',
    name: 'reorder_qty',
    selected: true
  },
  {
    displayName: 'Reorder Cost',
    value: 'reorder_cost',
    name: 'reorder_cost',
    selected: true
  },
  {
    displayName: 'Reorder Profit',
    value: 'reorder_profit',
    name: 'reorder_profit',
    selected: false
  },
  {
    displayName: 'Reorder Days of Supply',
    value: 'reorder_days_of_supply',
    name: 'reorder_days_of_supply',
    selected: true
  },
  {
    displayName: 'Lead Time',
    value: 'lead_time',
    name: 'lead_time',
    selected: false
  },
  {
    displayName: 'Days Remaining',
    value: 'days_remaining',
    name: 'days_remaining',
    selected: false
  },
  {
    displayName: 'Inventory Level',
    value: 'inventory_level',
    name: 'inventory_level',
    selected: true
  },
  {
    displayName: 'Inbound Level',
    value: 'inbound_level',
    name: 'inbound_level',
    selected: false
  },
  {
    displayName: 'Inventory Level Revenue',
    value: 'inventory_level_revenue',
    name: 'inventory_level_revenue',
    selected: false
  },
  {
    displayName: 'Inventory Level Cost',
    value: 'inventory_level_cost',
    name: 'inventory_level_cost',
    selected: false
  },
  {
    displayName: 'Inventory Level Profit',
    value: 'inventory_level_profit',
    name: 'inventory_level_profit',
    selected: false
  },
  {
    displayName: 'Average Daily Sales',
    value: 'average_daily_sales',
    name: 'average_daily_sales',
    selected: false
  },
  // {
  //   displayName: 'Supplier',
  //   value: 'supplier_name',
  //   name: 'supplier_name',
  //   selected: false
  // },
  {
    displayName: 'Average Profit per Unit',
    value: 'avg_profit_per_unit',
    name: 'avg_profit_per_unit',
    selected: false
  }
]

export const SALES_HISTORY_OPTIONS = [
  {
    name: '15 Days',
    value: 15,
    dataId: DataIds.Filters.HistoryDropdown.FifteenDays
  },
  {
    name: '30 Days',
    value: 30,
    dataId: DataIds.Filters.HistoryDropdown.ThirthyDays
  },
  {
    name: '60 Days',
    value: 60,
    dataId: DataIds.Filters.HistoryDropdown.SixtyDays
  },
  {
    name: '90 Days',
    value: 90,
    dataId: DataIds.Filters.HistoryDropdown.NinetyDays
  },
  {
    name: '120 Days',
    value: 120,
    dataId: DataIds.Filters.HistoryDropdown.HundredAndTwentyDays
  }
]

const DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS = [
  {
    displayName: 'Overstocked',
    name: 'excess',
    value: 'excess',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.Overstocked
  },
  {
    displayName: 'In Stock',
    name: 'in_stock',
    value: 'in_stock',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.InStock
  },
  {
    displayName: 'Reorder Soon',
    name: 'reorder_soon',
    value: 'reorder_soon',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.ReorderSoon
  },
  {
    displayName: 'Order Now',
    name: 'order_now',
    value: 'order_now',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.OrderNow
  }
]

export const INVENTORY_STATUS_FILTER_OPTIONS = [
  ...DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS,
  {
    displayName: 'No Status',
    name: 'no_status',
    value: 'no_status',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.NoStatus
  }
]
export const INVENTORY_FORECAST_SELECTED_COLUMNS =
  'INVENTORY_FORECAST_SELECTED_COLUMNS'

export const FILTERS_INITIAL_STATE = {
  averageDailySalesMax: '',
  averageDailySalesMin: '',
  dateToReorderMaxObject: null,
  dateToReorderMinObject: null,
  daysRemainingMax: '',
  daysRemainingMin: '',
  inboundLevelMax: '',
  inboundLevelMin: '',
  inventoryLevelMax: '',
  inventoryLevelMin: '',
  reorderQuantityMax: '',
  reorderQuantityMin: '',
  selectedStatus: DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS
  // suppliers: []
}
